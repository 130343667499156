/**
 * Promo List Component
 */
import React, { useEffect, useRef, useState } from 'react';
import { getCouponLabel } from '@/utils/getCouponLabel';
import { deviceWidth } from '@/utils/deviceWidth';
import { PercentageIcon } from '@/assets/svgExports/PercentageIcon';
import { useDispatch } from 'react-redux';
import { Slider } from '@/components/ReactSlickCarousel';
import { getCouponsList } from 'src/redux/actions';
import Modal from '../Common/Modal';
import { Button } from '../DesignSystem/AtomicComponents';
import { useRouter } from 'next/router';
import { getRoute, PATH } from '@/utils/routes';
import { useSSRSelector } from '@/redux/ssrStore';

const PromoList = (props) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const sliderRef = useRef();

  const [activeSlide, setActiveSlide] = useState(0);
  const [couponDetailOverlay, setCouponDetailOverlay] = useState({
    show: false,
    index: 0,
  });

  const { storeData, couponsList } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
    couponsList: state?.promoReducer?.couponsList,
  }));

  const [bannerImage, setbannerImage] = useState('');

  useEffect(() => {
    storeData?.store_id &&
      !couponsList?.length &&
      dispatch(getCouponsList(storeData.store_id));
  }, [storeData?.store_id, couponsList?.length]);

  useEffect(() => {
    storeData?.theme?.components?.Body?.forEach((layout) => {
      if (layout.sub_type === 'HeroBanner')
        setbannerImage(
          layout.images
            ?.filter((el) => el.is_desktop === false)
            .sort((a, b) => a.order - b.order)[0]?.image_url
        );
    });
  }, []);

  /**
   * Slider base values
   */
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    centerMode: deviceWidth > 990 ? false : true,
    slidesToShow: deviceWidth > 990 ? 3 : 1,
    swipeToSlide: true,
    slidesToScroll: 1,
    initialSlide: 0,
    dotsClass: 'dotted_icons',
    arrows: false,
    afterChange: (current) => setActiveSlide(current),
  };

  /**
   * slider prev button
   * click handler function
   */
  const onPrevClick = () => {
    if (sliderRef?.current?.slickPrev) {
      sliderRef?.current?.slickPrev();
    }
  };

  /**
   * slider next button
   * click handler function
   */
  const onNextClick = () => {
    if (sliderRef?.current?.slickNext) {
      sliderRef?.current?.slickNext();
    }
  };

  /**
   * Helper function to toggle
   * the Coupon details overlay
   * @param {number} idx
   */
  const onToggleCouponDetailsOverlay = (idx) => {
    setCouponDetailOverlay((state) => ({
      show: !state.show,
      index: state.show === false ? idx : 0,
    }));
  };

  const handleCategoryOnClick = (cid) => {
    router.push(getRoute(`${PATH.SHOP}?cid=${cid}`, storeData.store_info.domain));
  };
  const handleTagOnClick = (tid) => {
    router.push(getRoute(`${PATH.SHOP}?cid=${tid}`, storeData.store_info.domain));
  };

  /**
   * Helper for to render the
   * Coupon detail Modal
   * @return {JSX}
   */
  const _renderCouponDetail = () => {
    const coupon = couponsList[couponDetailOverlay.index];
    return (
      <Modal
        visible={true}
        height={300}
        animation={'fadeIn'}
        onClose={onToggleCouponDetailsOverlay}
        className="couponDetails-overlay"
        customStyles={{
          top: '50%',
          left: '50%',
          bottom: 'initial',
          right: 'initial',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          borderRadius: '15px',
          maxWidth: '450px',
        }}
        customMaskStyles={{
          background: '#000000e0',
        }}
      >
        <section className="topdesign relative br4 br--top h4">
          <img
            src={bannerImage || '/assets/images/bg-ribbon.png'}
            className="h-100 w-100 br4"
            alt="Promo Code background"
            loading="lazy"
          />
        </section>
        <section className="details  br4 br--bottom bg-white">
          <div className="f3 fw7 mt4 mb3">{getCouponLabel(coupon, true)}</div>
          <article>
            <span className="ba b--black-20 br2 pa1 f6 b--dashed">
              Use Code: <strong>{coupon?.promo_code}</strong>
            </span>
          </article>
          <p className="f7 black-100 mt3">
            Min. Order Amount: ₹{coupon?.min_order_price}{' '}
            {coupon?.once_per_user_flag && '| Valid once per user'}
          </p>
          {coupon.coupon_type !== 0 && (
            <div className={deviceWidth > 768 ? 'f16px fw6 mt30px' : 'f12px fw6 mt30px'}>
              Offer Valid On these Categories
            </div>
          )}
        </section>
        <div className="cross-icon absolute w-100 flex flex-column justify-center items-center">
          <p className="white-70 f7 tc flex justify-center items-center">
            <img src="/assets/images/ic_bulb.png" alt="bulb" className="img h1 mr2" /> Use
            this coupon while placing an order
          </p>
          <span className="bg-white-20 h2 w2 br4 flex justify-center items-center pointer">
            <img
              src="/assets/images/cancel_white.png"
              alt="cross"
              className=" img h1"
              onClick={onToggleCouponDetailsOverlay}
            />
          </span>
        </div>
        {
          <div className="flex flex-row flex-wrap">
            {coupon?.category_id_list?.map(
              (category) =>
                category.name && (
                  <Button
                    px="20px"
                    py="5px"
                    fontSize="12"
                    label={category.name}
                    backgroundColor="white"
                    fontColor="#474748"
                    borderColor="#DDDDDD"
                    roundness={4}
                    mt="15px"
                    mr="5px"
                    key={category.id}
                    hoverEnabled={true}
                    onClick={() => handleCategoryOnClick(category.id)}
                  ></Button>
                )
            )}
            {coupon?.tag_id_list?.map(
              (tag) =>
                tag.name && (
                  <Button
                    px="20px"
                    py="5px"
                    fontSize="12"
                    label={tag.name}
                    backgroundColor="white"
                    fontColor="#474748"
                    borderColor="#DDDDDD"
                    roundness={4}
                    mt="15px"
                    mr="5px"
                    key={tag.id}
                    hoverEnabled={true}
                    onClick={() => handleTagOnClick(tag.id)}
                  ></Button>
                )
            )}
          </div>
        }
      </Modal>
    );
  };

  const _renderCoupon2 = (coupon, idx) => {
    return (
      <div
        key={idx}
        className="coupon-card2 br3 relative flex"
        onClick={() => onToggleCouponDetailsOverlay(idx)}
      >
        <div
          style={{
            color: 'black',
            height: '100%',
            background: `linear-gradient(90deg, ${storeData?.theme?.colors?.primary_color} 0%, ${storeData?.theme?.colors?.primary_color} 30%, rgba(0,212,255,0) 100%)`,
          }}
        >
          <PercentageIcon color="#fff" className="absolute img h1" />
          <div className="left flex flex-column justify-center items-start white pl3 h-100">
            <h4 className="f5 mt0 mb1">{getCouponLabel(coupon)}</h4>
            <p className="codeText mv0">
              <div>Use Code:</div>
              <div className="f7 ">{coupon?.promo_code}</div>
            </p>
          </div>
          {!!bannerImage && (
            <div className="right">
              <img alt="promo code background" src={bannerImage} className="" />
            </div>
          )}
        </div>
      </div>
    );
  };

  const _renderCoupon3 = (coupon, idx) => {
    return (
      <div
        className="coupon-card2 br3 relative flex"
        key={idx}
        onClick={() => onToggleCouponDetailsOverlay(idx)}
      >
        <div
          style={{
            color: 'black',
            height: '100%',
            background: `linear-gradient(90deg, ${storeData?.theme?.colors?.primary_color} 0%, ${storeData?.theme?.colors?.primary_color} 30%, rgba(0,212,255,0) 100%)`,
          }}
        >
          <PercentageIcon color="#000" className="absolute img h1" />
          <div className="left flex flex-column justify-center items-start pl3 h-100 black">
            <h4 className="f5 mt0 mb1">{getCouponLabel(coupon)}</h4>
            <p className="codeText mv0">
              <div>Use Code:</div>
              <div className="f7">{coupon?.promo_code}</div>
            </p>
          </div>
          {!!bannerImage && (
            <div className="right">
              <img alt="promo code background" src={bannerImage} className="" />
            </div>
          )}
        </div>
      </div>
    );
  };

  const _renderCoupon4 = (coupon, idx) => {
    return (
      <div
        key={idx}
        className="coupon-card4 br3 relative flex"
        onClick={() => onToggleCouponDetailsOverlay(idx)}
      >
        <div
          style={{
            color: 'black',
            height: '100%',
            background: `linear-gradient(90deg, ${storeData?.theme?.colors?.primary_color} 0%, ${storeData?.theme?.colors?.primary_color} 30%, rgba(0,212,255,0) 100%)`,
          }}
        >
          <PercentageIcon color="#fff" className="absolute img h1" />
          <div className="left flex flex-column justify-center items-start white pl4 h-100">
            <h4 className="f6 mt0 mb1">{getCouponLabel(coupon)}</h4>
            <p className="codeText mv0">
              <div>Use Code:</div>
              <div className="f7 ">{coupon?.promo_code}</div>
            </p>
          </div>
          {!!bannerImage && (
            <div className="right">
              <img alt="promo code background" src={bannerImage} className="" />
            </div>
          )}
        </div>
      </div>
    );
  };
  const _renderCoupon5 = (coupon, idx) => {
    return (
      <div
        className="coupon-card4 br3 relative flex"
        key={idx}
        onClick={() => onToggleCouponDetailsOverlay(idx)}
      >
        <div
          style={{
            color: 'black',
            height: '100%',
            background: `linear-gradient(90deg, ${storeData?.theme?.colors?.primary_color} 0%, ${storeData?.theme?.colors?.primary_color} 30%, rgba(0,212,255,0) 100%)`,
          }}
        >
          <PercentageIcon alt="percentage" color="#000" className="absolute img h1" />
          <div className="left flex flex-column justify-center items-start black pl4 h-100">
            <h4 className="f6 mt0 mb1">{getCouponLabel(coupon)}</h4>
            <p className="codeText mv0">
              <div>Use Code:</div>
              <div className="f7">{coupon?.promo_code}</div>
            </p>
          </div>
          {!!bannerImage && (
            <div className="right">
              <img
                alt="promo code background"
                src={bannerImage}
                loading="lazy"
                className=""
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const _renderCoupon1 = (coupon, idx) => {
    return (
      <article
        key={idx}
        className="coupon-card1 br3 relative"
        onClick={() => onToggleCouponDetailsOverlay(idx)}
      >
        <PercentageIcon color="#EA5151" />
        <h4 className="f5 mt0 mb1">{getCouponLabel(coupon)}</h4>
        <p className="flex justify-start items-center f7 mv0 flamingo-color">
          <div>Use Code:</div>
          <div className="f6 ml1 ">{coupon?.promo_code}</div>
        </p>
      </article>
    );
  };

  const _renderCoupons = (coupon, idx, cardNumer) => {
    switch (cardNumer) {
      case 2:
        return _renderCoupon2(coupon, idx);
      case 3:
        return _renderCoupon3(coupon, idx);
      case 4:
        return _renderCoupon4(coupon, idx);
      case 5:
        return _renderCoupon5(coupon, idx);
      default:
        return _renderCoupon1(coupon, idx);
    }
  };

  return (
    couponsList?.length > 0 && (
      <div className="promoList bg-white">
        <section className={`header flex justify-between`}>
          <h3 className={`promoList__title fw6 tc center mv0`}>Offers</h3>
        </section>
        <>
          <section className={`header flex justify-between`}>
            {settings.slidesToShow < couponsList.length && deviceWidth > 990 && (
              <div className="action-wrapper flex justify-center items-center">
                <img
                  src="/assets/images/arrowb.png"
                  className={`img h1 mr2 ${activeSlide === 0 && 'o-20'}`}
                  onClick={onPrevClick}
                  alt="prev arrow"
                ></img>
                <img
                  src="/assets/images/arrowf.png"
                  className={`img h1 mr2 ${
                    (activeSlide === couponsList.length - 1 ||
                      activeSlide === couponsList.length - settings.slidesToShow) &&
                    'o-20'
                  }`}
                  onClick={onNextClick}
                  alt="next arrow"
                ></img>
              </div>
            )}
          </section>
          <Slider ref={(slider) => (sliderRef.current = slider)} {...settings}>
            {couponsList?.map((coupon, idx) => {
              return _renderCoupons(coupon, idx, props.cardNumer || 1);
            })}
          </Slider>
          {deviceWidth < 990 && (
            <section className="flex justify-center items-center mt2">
              {/* <input
               className=""
               value={activeSlide}
               type="range"
               min={0}
               max={couponsList.length - 1}
             /> */}
              <progress
                max={couponsList.length - 1}
                value={activeSlide}
                className="slider-scrollbar bg-grey"
                style={{
                  color: storeData?.theme?.colors?.primary_color || '#ea5151',
                }}
              ></progress>
            </section>
          )}
          {couponDetailOverlay.show && _renderCouponDetail()}
        </>
      </div>
    )
  );
};
export default PromoList;

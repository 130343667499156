/**
 *
 * @param {object} coupon
 * @param {boolean} modal
 * @param {boolean} isCategoryTagCoupon
 * @return {string}
 */
export const getCouponLabel = (coupon, modal, isCategoryTagCoupon = false) => {
  switch (coupon.discount_type) {
    case 'percentage':
      return isCategoryTagCoupon
        ? `${'Get upto '} ${coupon.discount}% off on ₹${
            coupon.min_order_price
          } and above. MAX DISCOUNT 
        ₹${coupon.max_discount}`
        : `${coupon.discount}% OFF ${modal ? `UPTO ₹${coupon.max_discount}` : ''}`;
    case 'flat':
      return `FLAT ₹${coupon.discount} OFF`;
  }
};
